import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { Link } from "gatsby"

import "bootstrap/dist/css/bootstrap.min.css"

const activeStyle = {
  color: `#646090`,
  textDecoration: "underline",
}

export const Navigation = () => (
  <Navbar
    fixed="top"
    expand="lg"
    style={{
      background: "rgba(255,255,255,1)",
      padding: "0.1rem 1rem",
      borderBottom: `0.5px solid rgba(0,0,0,0.2)`,
      boxShadow: `0px 4px 3px -3px rgba(0,0,0,0.3)`,
    }}
  >
    <Navbar.Brand>
      <Link
        to="/"
        style={{
          color: `#101010`,
          textDecoration: `none`,
        }}
      >
        <h3
          style={{
            margin: `0.25rem`,
          }}
        >
          Markiyan Melnychenko | Violin
        </h3>
      </Link>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav
        style={{
          justifyContent: `flex-end`,
          width: `100%`,
          padding: `0.5rem 0`,
        }}
      >
        <Nav.Item style={{ margin: `0.25rem 1rem 0.25rem 0.5rem` }}>
          <h4
            style={{
              margin: `0`,
            }}
          >
            <Link
              style={{
                color: `#101010`,
                textDecoration: `none`,
              }}
              activeStyle={activeStyle}
              to="/biography"
            >
              Biography
            </Link>
          </h4>
        </Nav.Item>
        <Nav.Item style={{ margin: `0.25rem 1rem 0.25rem 0.5rem` }}>
          <h4
            style={{
              margin: `0`,
            }}
          >
            <Link
              style={{
                color: `#101010`,
                textDecoration: `none`,
              }}
              activeStyle={activeStyle}
              to="/schedule"
            >
              Schedule
            </Link>
          </h4>
        </Nav.Item>
        <Nav.Item style={{ margin: `0.25rem 1rem 0.25rem 0.5rem` }}>
          <h4
            style={{
              margin: `0`,
            }}
          >
            <Link
              style={{
                color: `#101010`,
                textDecoration: `none`,
              }}
              activeStyle={activeStyle}
              to="/media"
            >
              Media
            </Link>
          </h4>
        </Nav.Item>
        <Nav.Item style={{ margin: `0.25rem 1rem 0.25rem 0.5rem` }}>
          <h4
            style={{
              margin: `0`,
            }}
          >
            <Link
              style={{
                color: `#101010`,
                textDecoration: `none`,
              }}
              activeStyle={activeStyle}
              to="/contact"
            >
              Contact
            </Link>
          </h4>
        </Nav.Item>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)
